@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply accent-tertiary-variant bg-[#F9F7F4] text-[#001232] !important;
  -webkit-tap-highlight-color: transparent;
}

h1 {
  font-family: larken, sans-serif;
}

.invisible-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.sr-only {
  border-width: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.portable-list li {
  @apply pl-1.5;
}

.info-box a,
.info-box p,
.info-box ul {
  @apply text-on-info-container text-base sm:text-lg;
}

.richtext-image + .richtext-image {
  @apply mt-5 lg:gap-5;
}

.richtext-image:has(+ .richtext-image) {
  @apply mb-5 lg:gap-5;
}

option:hover {
  background-color: yellow;
}

/* HERO GRID */

.hero-grid {
  display: grid;

  grid-template-columns:
    minmax(5rem, 1fr)
    5rem
    minmax(0, calc(720px - 5rem))
    minmax(0, calc(720px - 5rem))
    5rem
    minmax(5rem, 1fr);
  align-items: center;

  @media (width > 1440px) and (width < 1600px) {
    grid-template-columns:
      minmax(5rem, 1fr)
      minmax(0, calc(720px - 5rem))
      minmax(0, calc(720px - 5rem))
      minmax(5rem, 1fr);
  }

  @media (width <= 1024px) {
    grid-template-columns:
      minmax(2.5rem, 1fr)
      minmax(0, calc(720px - 5rem))
      minmax(0, calc(720px - 5rem))
      minmax(2.5rem, 1fr);
  }

  @media (width <= 640px) {
    grid-template-columns:
      minmax(1.5rem, 1fr)
      minmax(0, calc(720px - 5rem))
      minmax(0, calc(720px - 5rem))
      minmax(1.5rem, 1fr);
  }
}

.hero-grid > div:first-of-type {
  grid-column: 3 / 4;

  @media (width > 1440px) and (width < 1600px) {
    grid-column: 2 / 3;
  }

  @media (width < 1440px) {
    grid-column: 2 / 4;
  }

  @media (width <= 1024px) {
    grid-column: 2 / -2;
  }
}

.hero-grid > img {
  grid-column: 4 / 6;

  @media (width > 1440px) and (width < 1600px) {
    grid-column: 3 / 6;
  }

  @media (width <= 1440px) {
    grid-column: 4 / -1; /* Adjusted for taking up the remaining space */
  }

  @media (width <= 1024px) {
    display: none;
  }
}
